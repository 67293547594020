<template>
  <div>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                outlined
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-btn color="primary" @click="openDialog = true">Novo</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="condominiums"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >

            <v-icon
              v-if="verifyDeletePermission()"
              small
              class="mr-2"
              @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog
        v-model="openDialog"
        width="1000"
        :fullscreen="fullScreen"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            flat
            style="padding-right: 10px !important"
            dark
            color="primary"
          >
            <v-toolbar-title class="overline">Condominios</v-toolbar-title>
            <v-btn icon right absolute dark>
              <v-icon
                @click="fullScreen ? (fullScreen = false) : (fullScreen = true)"
                >{{ setIconScreen() }}</v-icon
              >
              <v-icon @click="closeDialog()">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <br />

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      outlined
                      v-mask="maskCnpj"
                      label="CNPJ"
                      v-model="condominium.idDocument"
                      @change="searchCompany()"
                      required
                      :loading="loading"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field
                      outlined
                      label="Nome"
                      v-model="condominium.name"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" class="mt-n8">
                    <v-text-field
                      outlined
                      v-mask="maskPhoneMobile"
                      label="Fone"
                      v-model="condominium.phone"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" class="mt-n8">
                    <v-text-field
                      outlined
                      label="Email"
                      v-model="condominium.email"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="5" class="mt-n8">
                    <v-text-field
                      outlined
                      label="Síndico"
                      v-model="condominium.syndic"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" class="mt-n8">
                    <v-text-field
                      outlined
                      v-mask="'#####-###'"
                      label="CEP"
                      v-model="condominium.address.zipCode"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="mt-n8">
                    <v-text-field
                      outlined
                      label="Logradouro"
                      v-model="condominium.address.street"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" class="mt-n8">
                    <v-text-field
                      outlined
                      label="Número"
                      v-model="condominium.address.number"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" class="mt-n8">
                    <v-text-field
                      outlined
                      label="Complemento"
                      v-model="condominium.address.complement"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" class="mt-n8">
                    <v-text-field
                      outlined
                      label="Bairro"
                      v-model="condominium.address.zone"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="mt-n8">
                    <v-text-field
                      outlined
                      label="Cidade"
                      v-model="condominium.address.city"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" class="mt-n8">
                    <v-text-field
                      outlined
                      label="UF"
                      v-model="condominium.address.state"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    :loading="loadingAction"
                    color="success"
                    @click="validate()"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import axios from "axios";
import { VMoney } from "v-money";

export default Vue.extend({
  name: "Company",
  directives: { money: VMoney },
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    tab: null,
    deleteDialog: false,
    condominiums: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    maskPhone: "(##)####-####",
    maskCnpj: "##.###.###/####-##",
    maskPhoneMobile: "(##)#####-####",
    banks: [],
    valid: true,

    condominium: {
      active: true,
      notes: "",
      name: "",
      syndic: "",
      idDocument: "",
      phone: "",
      email: "",
      address: {
        street: "",
        number: "",
        complement: "",
        zone: "",
        city: "",
        state: "",
        zipCode: "",
      },
    },

    id: "",
    openDialog: false,
    fullScreen: false,

    headers: [
      {
        text: "Razão Social",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Fone",
        align: "start",
        value: "phone",
        filtering: true,
      },
      {
        text: "CNPJ",
        align: "start",
        value: "idDocument",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },

    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },

    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    verifyDeletePermission() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.admin) return true;
      if (
        user &&
        user?.group?.permissions &&
        user?.group?.permissions.includes(
          "digitech_plus.immobile.condominiums.delete_one"
        )
      ) {
        return true;
      }
      return false;
    },
    deleteItem() {
      http.delete(`immobile/customers/${this.id}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.closeDialog();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getItems() {
      this.loading = true;
      http.get("immobile/condominiums").then((data) => {
        this.condominiums = data.data;
        this.loading = false;
      });
    },
    registerOrUpdate() {
      this.loadingAction = true;
      if (this.id) {
        http.put(`immobile/condominiums/${this.id}`, this.condominium).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("immobile/condominiums", this.condominium).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.openDialog = false;
      this.getItems();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    editItem(item) {
      console.log("CHAMOU EDITAR======", item);
      this.id = item.id;
      this.condominium = item;
      this.openDialog = true;
    },
    searchCompany() {
      if (this.condominium.idDocument) {
        this.loading = true;
        const cnpj = this.condominium.idDocument.replace(/([^\d])+/gim, "");
        axios
          .get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`)
          .then((res) => {
            const { data } = res;
            this.condominium.name = data.razao_social;
            this.condominium.address.street = data.logradouro;
            this.condominium.address.zone = data.bairro;
            this.condominium.address.city = data.municipio;
            this.condominium.address.state = data.uf;
            this.condominium.address.number = data.numero;
            this.condominium.address.complement = data.complemento;
            this.condominium.address.zipCode = data.cep;
          });
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getItems();
  },
});
</script>

<style>
.label-solo {
  font-size: 12px;
  color: grey;
}
</style>
